.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.btnColor {
  background-color: #f75394;
  color: white;
}

.bgColorScreen {
  background-color: #fff5f4;
}
.buttonPink {
   background-color: #f75394;
  color: white;
}

.buttonred {
   background-color: #f64e4e;
  color: white;
}
.ant-menu-item-selected .menu-name, .ant-menu-item:hover{
  color: rgba(255, 105, 180, 1)!important
}
.ant-menu-submenu-open .ant-menu-submenu-title span:nth-child(2), .ant-menu-submenu-open .ant-menu-submenu-arrow, .ant-menu-submenu-title:hover{
color: rgba(255, 105, 180, 1)!important
}
.ant-menu-submenu-open .ant-menu-submenu-title span:nth-child(1) svg{
  fill: rgba(255, 105, 180, 1)!important;}
.ant-menu-item-selected .anticon svg{
  fill: rgba(255, 105, 180, 1)!important
}
.ant-menu-item-selected::after{
  border-right:3px solid rgba(255, 105, 180, 1)!important
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected, .ant-menu-item:hover{
  background-color: rgba(255, 105, 180, 0.15)!important
}
 .ant-menu-item-selected a, .ant-menu-item-selected span:nth-child(2){
  color: rgba(255, 105, 180, 1)!important
}
.ant-menu-submenu:hover .ant-menu-submenu-arrow{
    color: rgba(255, 105, 180, 1)!important
}
.ant-menu-submenu-selected{
  color: rgb(69, 85, 96);
}

.otp-field {
    width: 38px;
    margin-right: 10px;
    padding-left: 12px;
    height: 40px;
}
.ant-modal-header {
  padding: 0 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
   color: rgba(255, 105, 180, 1) !important; 
   font-weight: 500;
}

.ant-tabs-ink-bar {
   position: absolute;
   background: rgba(255, 105, 180, 1);
   pointer-events: none;
}